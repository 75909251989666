<template>
  <div>
    <div :class="'mobileNavBar ' + deviceType">
      <div class="appBar-header">
        <p @click="cancel()" style="flex: 1"><img src="https://gcdn.bionluk.com/site/icon/ic_close_black.svg" alt=""></p>
        <div class="header-title-container">
          <p class="header-title">
            Düzenle
          </p>
        </div>
        <div style="flex: 1;display: flex;justify-content: flex-end">
          <button v-show="imageLoaded" class="super-button" @click="sendImageToEventBus()">Kaydet</button>
        </div>
      </div>
    </div>
    <div class="cropper-component-main">
      <vue-cropperjs
        v-show="imageLoaded"
        ref="cropper"
        :viewMode="this.viewMode"
        :aspectRatio="NaN"
        dragMode="move"
        :ready="runWhenCropperReady"
        :minContainerWidth="this.screenWidth"
        :minContainerHeight="this.screenHeight"
        :minCropBoxWidth="100"
        :minCropBoxHeight="100"
        :zoomOnWheel="false"
        :background="false"
        :toggleDragModeOnDblclick="false"
        :rotatable="false"
        :guides="false"
        :responsive="false"
        :cropBoxResizable="false"
        :cropBoxMovable="false"
      ></vue-cropperjs>
      <loader v-show="!imageLoaded"></loader>


      <div v-show="imageLoaded" id="slidecontainer">
        <input type="range" :min="minSliderRange" :max="maxSliderRange" step="0.01" :value="sliderRange" class="slider" style="width: 80vw">
        <div style="display: flex;justify-content: space-between">
          <img @click="zoomCrop(minSliderRange)" style="cursor: pointer; width: 16px; height: 16px; margin-right: 10px; opacity: 0.6;"
               src="https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-image-128.png"/>
          <img @click="zoomCrop(maxSliderRange)" style="cursor: pointer; width: 24px; height: 24px; margin-left: 10px; opacity: 0.6;"
               src="https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-image-128.png"/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import vueCropper from "vue-cropperjs";
  import Loader from "../../components/toolbox/loader.vue";

  export default {
    name: "cropper",
    components: {
      Loader,
      'vue-cropperjs': vueCropper
    },
    props: {
      /**
       * Slide transition easing
       * Any valid CSS transition easing accepted
       */

      file: {
        type: File,
        default: {},
      },

      canvasWidth: {
        type: Number,
        default: null,
      },

      canvasHeight: {
        type: Number,
        default: null,
      },

      divider: {
        type: Number,
        default: null,
      },

      viewMode: {
        type: Number,
        default: 1,
      },

      fileType: {
        type: String,
        default: null,
      }
    },
    data() {
      return {
        filename: null,
        sliderRange: 0,
        minSliderRange: 0,
        maxSliderRange: 5,
        screenWidth: window.innerWidth,
        screenHeight: (window.innerWidth / 1.77),
        imageLoaded: false,
        imageHeight: null,
        imageWidth: null,
      }
    },
//    containerStyle denenecek
    methods: {
      cancel() {
        this.EventBus.$emit('cropperComponentImageFile', { fileType: 0});
      },

      sendImageToEventBus() {
        let file = this.dataURLtoFile(
          this.$refs.cropper.getCroppedCanvas({"width": this.canvasWidth, "height": this.canvasHeight}).toDataURL(), this.filename);

        this.EventBus.$emit('cropperComponentImageFile', {file: file, fileType: this.fileType});
      },

      setImage(file) {
        if (!file.type.includes('image/')) {
          alert('Please select an image file');
          return;
        }
        this.filename = file.name;

        if (typeof FileReader === 'function') {
          const reader = new FileReader();

          reader.onload = (event) => {
            // rebuild cropperjs with the updated source
            this.$refs.cropper.replace(event.target.result);


          };

          reader.readAsDataURL(file);
        } else {
          alert('Sorry, FileReader API not supported');
        }
      },

      listenerForInput() {
        if (this.isMobile) {
          let rng = document.querySelector(".slider");
          let listener = () => {
            window.requestAnimationFrame(() => {
              this.sliderRange = rng.value;
            });
          };

          rng.addEventListener("touchstart", function () {
            listener();
            rng.addEventListener("touchmove", listener);
          });
          rng.addEventListener("touchend", function () {
            rng.removeEventListener("touchmove", listener);
          });
        } else {
          let rng = document.querySelector(".slider");
          let listener = () => {
            window.requestAnimationFrame(() => {
              this.sliderRange = rng.value;
            });
          };

          rng.addEventListener("mousedown", function () {
            listener();
            rng.addEventListener("mousemove", listener);
          });
          rng.addEventListener("mouseup", function () {
            rng.removeEventListener("mousemove", listener);
          });
        }
      },

      runWhenCropperReady() {
        let cropHeight = this.canvasHeight / this.divider;
        let cropWidth = this.canvasWidth / this.divider;

        let ratioX = (this.imageWidth < this.imageHeight ? cropWidth : cropHeight) / (this.imageWidth < this.imageHeight ? this.imageWidth : this.imageHeight);
        let ratioY = (this.imageWidth >= this.imageHeight ? cropWidth : cropHeight) / (this.imageWidth > this.imageHeight ? this.imageWidth : this.imageHeight);
        let finalRatio = ratioX > ratioY ? ratioX : ratioY;

        this.minSliderRange = finalRatio;
        this.sliderRange = finalRatio;
        this.$refs.cropper.zoomTo(finalRatio);
        this.$refs.cropper.setCropBoxData({
          "top": (this.screenHeight-cropHeight) / 2,
          "left": (this.screenWidth-cropWidth) / 2,
          "width": cropWidth,
          "height": cropHeight
        });
        this.imageLoaded = true;
      },

      zoomCrop(value) {
        this.sliderRange = value;
      },

      initProps() {
        let a = window.URL || window.webkitURL;

        let file, img;
        let that = this;
        if ((file = this.file)) {
          img = new Image();
          img.onload = function () {
            that.imageHeight = this.height;
            that.imageWidth = this.width;
            that.setImage(that.file);
          };
          img.src = a.createObjectURL(file);
        }
      }
    },

    watch: {
      sliderRange: function (newVal, oldVal) {
        this.$refs.cropper.zoomTo(newVal);
      }
    },

    created() {
      Object.assign(this.$data, this.$options.data());
    },
    mounted() {
      this.initProps();
      this.listenerForInput();
    }
  }

</script>

<style scoped lang="scss">
  .appBar-header{
    align-items: center;
  }
  .cropper-component-main {
    /*width: 650px !important;;*/
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
  }

  .super-button {
    height: 34px;
    min-width: 100px;
    background-color: #67cc64;
  }

  /*slider*/

  #slidecontainer {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }

  input[type=range] {
    /*removes default webkit styles*/
    -webkit-appearance: none;

    /*fix for FF unable to apply focus style bug */
    border: 1px solid white;

    /*required for proper track sizing in FF*/
    width: 300px;
  }

  input[type=range]::-webkit-slider-runnable-track {
    width: 300px;
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px;
  }

  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: goldenrod;
    margin-top: -4px;
  }

  input[type=range]:focus {
    outline: none;
  }

  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #ccc;
  }

  input[type=range]::-moz-range-track {
    width: 300px;
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px;
  }

  input[type=range]::-moz-range-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: goldenrod;
  }

  /*hide the outline behind the border*/
  input[type=range]:-moz-focusring {
    outline: 1px solid white;
    outline-offset: -1px;
  }

  input[type=range]::-ms-track {
    width: 300px;
    height: 5px;

    /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
    background: transparent;

    /*leave room for the larger thumb to overflow with a transparent border */
    border-color: transparent;
    border-width: 6px 0;

    /*remove default tick marks*/
    color: transparent;
  }

  input[type=range]::-ms-fill-lower {
    background: #777;
    border-radius: 10px;
  }

  input[type=range]::-ms-fill-upper {
    background: #ddd;
    border-radius: 10px;
  }

  input[type=range]:focus::-ms-fill-lower {
    background: #888;
  }

  input[type=range]:focus::-ms-fill-upper {
    background: #ccc;
  }

  input[type=range]::-ms-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: goldenrod;
  }

  input[type=range]::-moz-focus-outer {
    border: none;
  }

  input:invalid {
    box-shadow: none;
  }
</style>
