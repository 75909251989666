<template>
  <div id="freelancerDiv" style="background: #fff;">
    <div :class="'mobileNavBar ' + deviceType">
      <div class="appBar-header">
        <div @click="routeBack('/bugun',false)" class="header-route-back">
          <span v-if="deviceType === 'ios'">
            <img class="header-route-back-image-ios" :src="$root.CDN_SITE + 'icon/tansel_mobile_black_back.svg'">
          </span>
          <span v-else>
            <img class="header-route-back-image-android" :src="$root.CDN_SITE + 'icon/tansel_mobile_black_back.svg'">
          </span>
        </div>
        <div class="header-title-container">
          <p class="header-title">
            Freelancer Ol
          </p>
        </div>
      </div>
    </div>
    <cropper v-if="openCropper"
             :file="file"
             :fileType="fileType"
             :canvasWidth="canvasWidth"
             :canvasHeight="canvasHeight"
             :divider="divider"
             :viewMode="viewMode"
             class="mobile-cropper" style="margin-top: 56px;">
    </cropper>

    <div style="padding:100px 15px 100px; display:none;" class="mobilepageContainer">
      <span>Bu sayfayı geçici olarak mobil cihazlarda gösteremiyoruz. Gerekli düzenleme yapılana dek masaüstü bilgisayarlarla ilgili işlemi yapabilirsin.</span>
    </div>
    <div v-if="!openCropper" class="mobilepageContainer" style="padding:56px 15px 100px">
      <div v-if="activeStep === 'describeYourself'" class="section1" style=" background-color: white; font-size: 16px">
        <h3 style="color: #2d3640;padding: 5px 0">Hakkında </h3>
        <div style="display: flex;flex-direction: column">
          <div>
            <label style="font-weight: 400; font-size: 20px;  margin-bottom: 10px; text-align: left; ">Profil Fotoğrafı</label>
            <span style="font-size: 16px; color: #8b95a1; font-weight: 300; padding-right: 10px;">Seni en iyi yansıttığını düşündüğün profil resmini ekle! Böylelikle müşterilerin tam olarak kiminle çalışacaklarını bilirler.</span>
          </div>
          <div style="display: flex;flex-direction: column;align-items: center">
            <img style="width: 100px;  margin-top: 20px; height: 100px;" v-show="uploadResponseStatus !== 'started'" class="avatar_image" :src=user.avatar_url>
            <div v-show="uploadResponseStatus === 'started'" class="loader"></div>

            <div v-show="uploadResponseStatus !== 'started'" style="display: flex;flex-direction: column;align-items: center">
              <label style=" cursor: pointer" for="files" class="button-rounded-light">+ Fotoğraf Ekle</label>
              <input id="files" style="visibility:hidden;" type="file" @change="onFileChangeToUploadCover">
            </div>
          </div>
          <progress v-if="uploadResponseStatus === 'started'" :value='percentCompleted["onboarding"][timeStamp]' max="100"></progress>
        </div>
        <div class="content-whitex" style="margin-left:0; background-color: inherit!important;">
          <div style="display: flex; flex-direction: column">
            <div>
              <label style="font-weight: 400; font-size: 20px;  margin-bottom: 10px; text-align: left; ">Kendini tanıt.</label>
              <span style="font-size: 16px; color: #8b95a1; font-weight: 300; padding-right: 10px;">Seni en iyi tanımlayan hangisi?</span>
            </div>
            <div>
              <select v-model="describeYourself" name="describeYourself" class="super-drop" style="background-position-x: 320px;font-size: 18px;margin: 10px 0;">
                <option v-for="describeYourselfJob in describeYourselfJobs" :value="describeYourselfJob">{{ describeYourselfJob.name }}</option>
              </select>
            </div>
          </div>
          <div class="hiddendiv"></div>

          <div style="display: flex; flex-direction: column">
            <div>
              <label style="font-weight: 400; font-size: 20px;  margin-bottom: 10px; text-align: left; ">Doğum Tarihi</label>
            </div>
            <div>
              <!--Doğum Tarihin-->
              <div style="font-size: 16px; color: #8b95a1; font-weight: 300; padding-right: 10px;">
                Bu bilgiyi kesinlikle profilinde göstermeyeceğiz fakat Bionluk’ta freelancer olmak için <span class="bold">18 yaşından büyük olman</span> gerekir. 😊
              </div>
              <div class="hiddendiv"></div>
              <div class="birthday-select-form">


                <custom-select label="Gün"
                               id="day"
                               :options="dayArr"
                               v-model="day"
                               :valid="!!day ? true : null"
                               labelPosition="center"
                               style="width: 80px; margin-right: 20px;"/>

                <custom-select label="Ay"
                               id="month"
                               :options="monthArr"
                               v-model="month"
                               :valid="!!month ? true : null"
                               labelPosition="center"
                               style="width: 80px; margin-right: 20px;"/>

                <custom-select label="Yıl"
                               id="year"
                               :options="yearArr"
                               v-model="year"
                               :valid="!!year ? true : null"
                               labelPosition="center"
                               style="width: 80px;"/>
              </div>

            </div>
          </div>
          <div class="hiddendiv"></div>
          <div class="hiddendiv"></div>

          <div style="display: flex;flex-direction: column">
            <div style="flex: 1">
              <label style="font-weight: 400; font-size: 20px; margin-bottom: 10px; text-align: left; ">
                Yetenekler
              </label>
              <span style="font-size: 16px; color: #8b95a1; font-weight: 300; padding-right: 10px;">
                Alıcılar uzmanlık alanlarını bilmek isterler, önceki tecrübelerinde kazandığın yeteneklerini bizimle paylaş.
              </span>
            </div>
            <div>
              <div v-if="openSkillInput || addedSkills.length === 0" style="border-radius: 1px; border: 1px solid #e1e1e1;margin: 20px 0;">
                <div style="margin: 10px; position: relative;">
                  <input v-model="searchTerm"
                         style="width: 90%; font-size: 16px; font-weight: 300;"
                         placeholder="Örn: Adobe Photoshop">
                  <div class="search-result-container" v-if="skillResultResults.length > 0 && searchTerm.length > 0">
                    <div class="search-result-users-container">
                      <div class="search-result-user"
                           v-for="(skillResultResult, index) in skillResultResults"
                           @click="addSkill(skillResultResult)">
                        <p v-if="index < 7" style="margin-top: 2px; font-size: 14px;line-height: 2.29;text-align: left;color: #2d3640;">{{skillResultResult.name}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <table v-if="addedSkills.length > 0" class="onboardingtable" style="margin: 20px 0">
                <thead>
                <tr>
                  <th>Yetenek</th>
                  <th style="text-align: right; color: #37414b; font-weight: 600; font-size: 14px; ">
                    <span @click="addNewSkill()" style="cursor: pointer;">
                      <span style="cursor: pointer; font-size: 22px; line-height: 14px;">+</span> Yeni ekle
                    </span>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="addedSkill in addedSkills" style="background-color: white;">
                  <td style="padding: 18px 15px;border-bottom: 1px solid #e5e5e5;">{{addedSkill.name}}</td>
                  <td style="padding: 18px 15px; border-bottom: 1px solid #e5e5e5; text-align: right;">
                    <img class="trcop" @click="deleteSkillArray(addedSkill)" src="https://gcdn.bionluk.com/site/icon/ic_delete.svg"/>
                  </td>
                </tr>

                </tbody>
              </table>

            </div>
          </div>




          <div class="hiddendiv"></div>

          <div style="display: flex; flex-direction: column">
            <div>
              <label style="font-weight: 400; font-size: 20px; margin-bottom: 10px; text-align: left; ">
                Başlık
              </label>
              <span style="font-size: 16px; color: #8b95a1; font-weight: 300; padding-right: 10px;">
                  Profil ve ilan sayfalarında gösterilir.
              </span>
            </div>
            <div style="display: flex;flex-direction: column">
              <input style="font-size: 18px;padding-right: 0;margin-top: 20px" :maxlength="maxTitleLength" v-model="professionalTitle" placeholder="Örn: Tasarımcı">
              <p style="font-size: 13px; color: #8b95a1;">{{ minTitleLength }}-{{ maxTitleLength }} karakter arası olmalıdır.
                <span style="float: right;">{{professionalTitle ? professionalTitle.length : 0}}/<span style="color: #575757; font-weight: bold">{{ maxTitleLength }}</span></span></p>
            </div>
          </div>


          <div class="hiddendiv"></div>


          <div class="hiddendiv"></div>
          <div style="text-align: center">
            <button :disabled="loading" class="super-button-green" style="padding: 10px 20px" @click="finishButton()">
              ÖDEME YAP VE BİTİR (69.90 TL)
            </button>
          </div>


        </div>

        <div class="hiddendiv10"></div>
      </div>
      <div v-if="activeStep === 'thanks'" class="section_done">

        <div class="notodoitems content-white" style="margin-left: 0; margin-top: 70px; text-align: center;">
          <div class="numberCircle" style="border:6px solid #dde3eb;width: 100px; height: 100px; margin: 0 auto; text-align: center">
              <span style="line-height: 60px;width: 100px; height: 100px; padding-top: 15px;">
                <img style="width: 60px; height: 60px;" :src="$root.CDN_SITE + 'icon/ic_done_gray.svg'">
              </span>
          </div>
          <div class="hiddendiv20"></div>


          <template v-if="describeYourself.path === 'toGig'">
            <p class="h1_2" style=" font-size:16px; font-weight:400!important;color: #8b95a1!important;">Harika! Şimdi yetenekli olduğun alanda iş ilanı oluşturabilirsin!</p>
            <div class="hiddendiv"></div>
            <router-link class="editButton" style="padding: 4px 15px 6px;" :to="'/panel/ilanlar/yeni'">İş İlanı Oluştur</router-link>
          </template>
          <template v-else>
            <p class="h1_2" style=" font-size:16px; font-weight:400!important;color: #8b95a1!important;">Harika! Şimdi yetenekli olduğun alanda iş ilanı oluşturabilirsin!</p>
            <div class="hiddendiv"></div>
            <router-link class="editButton" style="padding: 4px 15px 6px;" :to="'/panel/ilanlar/yeni'">İş İlanı Oluştur</router-link>
          </template>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import cropper from '../../../components/cropper/cropper_mobile.vue';
import CustomSelect from '../../../components/CustomSelect';

export default {
    name: "src-pages-body-onboarding-freelancer-v3_mobile",
    components: {
      Multiselect,
      cropper,
      CustomSelect
    },
    data() {
      return {

        dayArr: [],
        day: null,
        monthArr: [],
        month: null,
        yearArr: [],
        year: null,



        loading: false,
        activeStep: "describeYourself",
        skills: [],
        describeYourself: {
          value: null,
          name: "Seç..."
        },
        uploadResponseStatus: null,
        timeStamp: null,

        avatar_url: null,
        isUserHavePhoto: null,
        professionalTitle: "",
        description: "",
        searchTerm: "",
        skillResultResults: [],
        addedSkills: [],
        openSkillInput: true,
        openCropper: false,
        screenWidth: window.innerWidth,
        screenHeight: (window.innerWidth / 1.77),
        maxTitleLength: 25,
        minTitleLength: 5,
        maxBriefLength: 600,
        minBriefLength: 150,

        //      for  cropper
        file: null,
        canvasWidth: window.innerWidth,
        canvasHeight: (window.innerWidth / 1.77),
        divider: 2,
        viewMode: 1,
        fileType: "onFileChangeToUploadCover",
        describeYourselfJobs: [
          {
            value: null,
            name: "Seç..."
          },
          {
            value: "Consultant",
            name: "Danışman"
          },
          {
            value: "Data Scientist",
            name: "Data Scientist"
          },
          {
            value: "Developer",
            name: "Yazılım Uzmanı"
          },
          {
            value: "Designer",
            name: "Tasarımcı"
          },
          {
            value: "Influencer",
            name: "Influencer"
          },
          {
            value: "Marketer",
            name: "Pazarlama Uzmanı"
          },
          {
            value: "Musician",
            name: "Müzisyen"
          },
          {
            value: "Translator",
            name: "Çevirmen"
          },
          {
            value: "Video Maker",
            name: "Video/Prodüksiyon Uzmanı"
          },
          {
            value: "Virtual Asistant",
            name: "Sanal Asistan"
          },
          {
            value: "Voiceover Artist",
            name: "Seslendirmen"
          },
          {
            value: "Writer",
            name: "Yazar"
          },
        ],

        birthDate: null

      }
    },
    methods: {
      initializeDate() {
        for (let i = 1; i <= 31; i++) {
          let day = '';
          if (i < 10) day = '0' + i.toString();
          else day = i.toString();
          this.dayArr.push(day)
        }


        for (let i = 1; i < 13; i++) {
          let month = '';
          if (i < 10) month = '0' + i.toString();
          else month = i.toString();
          this.monthArr.push(month)
        }

        for (let i = 1930; i < new Date().getFullYear() - 17; i++) {
          const year = i.toString();
          this.yearArr.push(year)
        }
      },
      isLeapYear(year) {
        return Number(year) % 4 === 0;
      },
      changeActiveStep(step) {
        this.activeStep = step;
      },
      onFileChangeToUploadCover(e) {
        let files = e.target.files || e.dataTransfer.files;

        if(files && files[0]){

          this.api.general.checkCoverIsImage(files[0].type)
            .then(({data}) => {
              let result = data;
              this.activeProgress = null;
              if (result.success) {
                this.file = files[0];
                this.canvasWidth = this.screenWidth;
                this.canvasHeight = this.screenHeight;
                this.divider = 1.2;
                this.viewMode = 1;
                this.fileType = "onFileChangeToUploadCover";
                this.openCropper = true;
                e.target.value = '';
              } else {
                e.target.value = '';
                this.$toasted.global.errorToast({description: result.message});
              }
            })
            .catch(err => {
              e.target.value = '';
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            });
        }

      },
      setInitialValues() {
        this.avatar_url = this.user.avatar_url;
        this.professionalTitle = this.user.info.title && this.user.info.title.length ? this.user.info.title : "";
        this.brief = this.user.info.brief && this.user.info.brief.length ? this.user.info.brief : null;
        this.description = this.user.info.brief && this.user.info.brief.length ? this.user.info.brief : null;
      },
      eventBusOnFileChangeToUploadCover(payload) {
        this.responseStatusToUploadCover = "started";
        this.timeStamp = +new Date();
        this.uploadFileToGoogle(payload.file, this.Constants.UPLOAD_TYPES.AVATAR, 'onboarding', this.timeStamp)
          .then(result => {
            this.$store.state.user.avatar_url = this.Constants.CDN_UPLOADS_G + result.uploadName;
            this.responseStatusToUploadCover = null;
	          this.Helper.trackEvents.customEvent("uploaded_avatar", {version:'v1',whereFrom:this.$store.state.componentMap.page,clickedFrom:'become a freelancer'});
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: err ? err.message : this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.uploadResponseStatus = null;
          });
      },
      finishButton() {
        this.saveFreelancerInformation(1)
      },
      saveFreelancerInformation(isControl) {
        let skills = "";

        if (this.addedSkills.length > 0) {
          this.addedSkills.map(function (skill, index) {
            skills = skills + (index === 0 ? "" : ",") + skill.id;
          });
        }

        this.loading = true;


        let birthDate = null;
        if (this.day && this.month && this.year) {
          birthDate = this.day + '-' + this.month + '-' + this.year;
        }

        this.api.seller.saveFreelancerInformationV5(this.describeYourself.value, this.description, this.professionalTitle, skills, birthDate, isControl, this.$Progress)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              if (result.data && result.data.ok && result.data.ok === 'go') {
                isControl = 0;
              }
              if (isControl) {
                const data = {
                  data: {
                    type: "item",
                    total: 69.90,
                    itemId: 1,
                    cards:result.data.cards
                  }
                };
                this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.CHECKOUT, info: {checkOutData: data, isSinglePay: true}});
              } else {
                this.$toasted.global.infoToast({description: result.message});
                this.changeActiveStep('thanks');
                this.$store.state.user.seller = {isSeller: true};
                window.scrollTo(0, 0);
                this.setRedirectURLToRouterMap("/become_a_freelancer/", "/feed");
              }

            } else {
              this.$toasted.global.errorToast({description: result.message});
            }

            this.loading = false;
          })
          .catch(err => {
            this.loading = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      deleteSkillArray(skill) {
        const foundIndex = this.addedSkills.findIndex(({ id }) => id === skill.id)
        this.addedSkills.splice(foundIndex, 1)
      },
      getAllSkills() {
        this.api.general.getAllSkills()
          .then(({ data }) => {
            this.skills = data.data.filter(({ parent_id }) => !!parent_id)
            this.skills.forEach(skill => {
              skill.name = skill.name.tr_TR
            })
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      addSkill(skill) {
        if (this.addedSkills.length < 6) {
          this.addedSkills.push(skill);
          this.openSkillInput = false;
          this.searchTerm = "";
        } else {
          this.$toasted.global.errorToast({description: "En fazla 6 adet yetenek şecebilirsin."});
        }
      },
      addNewSkill() {
        if (this.addedSkills.length < 6) {
          this.openSkillInput = true;
        } else {
          this.$toasted.global.errorToast({description: "En fazla 6 adet yetenek şecebilirsin. Bir tanesini silerek tekrar eklemeyi deneyebilirsin."});
        }
      },
      turkishCharacterReplace(str) {
        str = str
          .replace(/ü/g, "u")
          .replace(/Ü/g, "U")
          .replace(/ö/g, "o")
          .replace(/Ö/g, "O")
          .replace(/ğ/g, "g")
          .replace(/Ğ/g, "G")
          .replace(/ş/g, "s")
          .replace(/Ş/g, "S")
          .replace(/ç/g, "c")
          .replace(/Ç/g, "C")
          .replace(/ı/g, "i")
          .replace(/İ/g, "I");

        return str;
      }
    },
    watch: {
      describeYourself(newVal) {
        if (newVal && newVal.name) {
          this.professionalTitle = newVal.name
        }
      },
      searchTerm(newValue, oldValue) {
        let searchValue = this.turkishCharacterReplace(newValue.trim().replace(/ /g, "")).toLowerCase();
        if (0 < searchValue.length && searchValue.length < 20) {
          let arr = [];
          this.skills.map((skill) => {
            let skillName = skill.slug.tr_TR.replace(/-/g, "").toLowerCase();
            let isExist = null;
            if (skillName.includes(searchValue)) {
              isExist = this.addedSkills.find(({ id }) => id === skill.id)
              if (!isExist) {
                arr.push(skill);
              }
            }
          });
          this.skillResultResults = arr;
        } else {
          this.skillResultResults = [];
        }
      },
      year(newVal) {
        if (newVal) {
          this.dayArr = [];
          let days = 31;
          const month = Number(this.month);
          const year = Number(newVal);
          if (month === 1 || month === 3 || month === 5 || month === 7
            || month === 8 || month === 10 || month === 12)
            days = 31;
          else if (month === 4 || month === 6 || month === 9
            || month === 11)
            days = 30;

          if (month === 2 && this.isLeapYear(year)) {
            days = 29;
          } else if (month === 2) {
            days = 28;
          }

          for (let i = 1; i <= days; i++) {
            let day = '';
            if (i < 10) day = '0' + i.toString();
            else day = i.toString();
            this.dayArr.push(day)
          }
          if (this.day && Number(this.day) > Number(this.dayArr[this.dayArr.length - 1]))
            this.day = this.dayArr[this.dayArr.length - 1];
        }
      },
      month(newVal) {
        if (newVal) {
          this.dayArr = [];
          let days = 31;
          const month = Number(newVal);
          const year = Number(this.year);
          if (month === 1 || month === 3 || month === 5 || month === 7
            || month === 8 || month === 10 || month === 12)
            days = 31;
          else if (month === 4 || month === 6 || month === 9
            || month === 11)
            days = 30;

          if (month === 2 && this.isLeapYear(year)) {
            days = 29;
          } else if (month === 2) {
            days = 28;
          }

          for (let i = 1; i <= days; i++) {
            let day = '';
            if (i < 10) day = '0' + i.toString();
            else day = i.toString();
            this.dayArr.push(day)
          }
          if (this.day && Number(this.day) > Number(this.dayArr[this.dayArr.length - 1]))
            this.day = this.dayArr[this.dayArr.length - 1];
        }
      }
    },
    created() {
      window.scrollTo(0, 0);
      this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.PRICING});
      this.getAllSkills();
      this.setInitialValues();
      this.EventBus.$on('cropperComponentImageFile', payload => {
        if (payload.fileType === "onFileChangeToUploadCover") {
          this.eventBusOnFileChangeToUploadCover(payload);
          this.openCropper = false;
        }

        if (payload.fileType === 0) {
          this.openCropper = false;
        }
      });
      this.EventBus.$on('isSinglePayed', (payload) => {
        if (payload.isSinglePayed) {
          this.saveFreelancerInformation(0);
        } else {
          this.$toasted.global.errorToast({description: 'Ödeme Başarısız Oldu'});
        }
      });
      this.isUserHavePhoto = this.user.avatar_url.split("/")[this.user.avatar_url.split("/").length - 1] !== "avatar_empty.png";

      this.initializeDate();
    },
    beforeDestroy() {
      this.EventBus.$off("cropperComponentImageFile");
    }
  }
</script>

<style scoped lang="scss">
  .search-result-container {
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 47.5px;
    display: flex;
    flex-direction: column;
    left: 0;
  }

  .search-result-users-container {
    max-width: 442px;
    padding: 15px 19px 5px 19px;
    background-color: #f4f5f7;
  }

  .search-result-user:hover {
    text-decoration: underline;
    cursor: pointer;

  }

  input.cov-datepicker {
    display: inline-block;
    width: 330px !important;
    line-height: 22px;
    font-size: 16px;

    box-shadow: none !important;
    border-radius: 2px;
    color: red !important;
  }

  .ui.celled.table tr td, .ui.celled.table tr th {
    /* border-left: 1px solid rgba(34,36,38,.1); */
  }

  .ui.table tr:first-child td {
    border-top: none;
  }

  .ui.celled.table tr td:first-child, .ui.celled.table tr th:first-child {
    border-left: none;
  }

  .ui.basic.table td {
    background: 0 0;
  }

  .ui.table tr td {
    border-top: 1px solid rgba(34, 36, 38, .1);
  }

  .ui.table td {
    padding: .78571429em .78571429em;
    text-align: inherit;
  }

  .ui.table td, .ui.table th {
    -webkit-transition: background .1s ease, color .1s ease;
    transition: background .1s ease, color .1s ease;
  }

  td, th {
    padding: 0;
  }

  .button-rounded-light {
    cursor: pointer;
    height: 30px !important;

    border-radius: 15px;
    width: auto;
    max-width: 100px;
    border: none;
    line-height: 30px;
    font-size: 12px;
    font-weight: 600;
    color: #2d3640;
    padding: 0 15px;
  }

  .button-rounded-light:hover {

    text-decoration: underline;
  }

  .numberCircle {

    border-radius: 50%;
    width: 36px;
    font-size: 28px;
    border: 2px solid #2d3640;

  }

  .numberCircle span {

    text-align: center;
    line-height: 36px;
    display: block;
    color: #5e6b79;
  }

  .onboardingtable {
    width: 100%;
    font-size: 14px;
    border: 1px solid #e5e5e5;
    border-spacing: 0;
    background-color: #eaedf2;
    border-radius: 2px;
    border-collapse: separate;
    text-transform: capitalize;
  }

  .onboardingtable thead {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
  }

  .onboardingtable th {
    color: #2d3640;
    width: 40%;
    background-color: #eaedf2;
    font-weight: 400;
    text-align: left;
    padding: 18px 15px;
    font-size: 14px;
  }

  .trcop {
    cursor: pointer;
    opacity: .65;
    width: 12px;
  }

  .mobile-cropper {
    display: flex;
    align-items: center;
  }

  .section_done {
    display: flex;
  }

  .avatar_image {
    width: 100px;
    margin-top: 20px;
    height: 100px;
    border: 1px solid #bfc8d2;
  }


  .birthday-select-form {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .bold {
    font-weight: 600;
    color: #2d3740;
  }
</style>
